import axios from "axios";
import React from "react";
import { BaseUrl } from "../common/api";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PrimaryButton } from "../assets/static/js/Button";
import Countdown from "react-countdown";
const cookies = new Cookies();
var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substring(0, 10);

class freeSize extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables
    this.state = {
      regions: [],
      region: "",
      region_id: "",
      districts: [],
      district: "",
      name: "",
      to_date: date,
      address: "",
      comment: "",
      phone: "",
      show: false,
      countdown: false,
      message: "",
      invalidOTP: false,
      AlreadyOTP: false,
      MaximumOTP: false,
    };
    this._isMounted = false;
    // detecting the language
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
    } else {
      this.name = "nameRu";
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    // fetching regions data
    await axios.get(BaseUrl + "regions/").then((res) => {
      const regions = res.data;
      this._isMounted &&
        this.setState({
          regions,
        });
    });
    const id = parseInt(cookies.get("Region"));
    const reg = this.state.regions.find((item) => item.id === id);

    // fetching districts data related to region

    await axios.get(BaseUrl + `districts/${id}/regions/`).then((res) => {
      const districts = res.data;
      this._isMounted &&
        this.setState({
          districts,
          region: reg,
          region_id: id,
        });
    });
  }

  // getting data which filled by user

  handleChange = (event) => {
    this._isMounted &&
      this.setState({
        [event.target.name]: event.target.value,
      });
  };
  // checking all essential fields before sending post method
  middlefunction = (e) => {
    this.postOrder();
  };

  // post user order to api

  postOrder = (e) => {
    if (
      this.state.name.length === 0 ||
      this.state.phone.length === 0 ||
      this.state.to_date.length === 0 ||
      this.state.district.length === 0 ||
      this.state.address.length === 0
    ) {
      this._isMounted &&
        this.setState({
          error: true,
        });
    } else {
      this.setState({ countdown: true });

      const data = {
        name: this.state.name,
        phone: this.state.phone,
        to_date: this.state.to_date,
        region: this.state.region_id,
        district: this.state.district,
        address: this.state.address,
        comment: this.state.comment,
      };
      axios
        .post(BaseUrl + "service/", data)
        .then((response) => {
          console.log(response.data);
          this.setState({
            service_id: response.data.id,
            otp_id: response.data.otp_id,
          });
          console.log(this.state);
        })
        .catch((error) => {
          console.log(error);
          console.log(error.message);
          console.log(error.response);
        });
    }
  };
  // verifying a one time password sended by playmobile

  verifyOTP = () => {
    this.setState({
      MaximumOTP: false,
      invalidOTP: false,
      AlreadyOTP: false,
    });
    const data = {
      otp: this.state.otp,
    };
    axios
      .post(BaseUrl + "otp/verify/" + this.state.otp_id + "/", data)
      .then((response) => {
        this.setState({ show: true });
        // console.log(this.state.url);
        this.props.history.push("/");
      })
      .catch((error) => {
        console.log(error);
        console.log(error.message);
        console.log(error.response);
        switch (error.response.data.error) {
          case "Invalid OTP.":
            this.setState({ invalidOTP: true });
            break;
          case "Maximum attempts exceeded.":
            this.setState({ MaximumOTP: true });
            break;
          case "OTP code has expired or already verified.":
            this.setState({ AlreadyOTP: true });
            break;
        }
      });
  };
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { t } = this.props;
    const { countdown, service_id, invalidOTP, AlreadyOTP, MaximumOTP } =
      this.state;
    return (
      <div className="main">
        <div className="container basket pt-2 pb-3">
          <div className="basket-left p-2">
            <div className="basket-left-top">
              {/* starting  input forms */}
              <h1 className="text-title">{t("requiredInfo.text")}</h1>
              <div className="form-item">
                <div className="area flex flex-column">
                  <label className="text-sm text-medium">
                    {t("yourName.text")}
                  </label>
                  <input
                    className="base-input"
                    type="text"
                    name="name"
                    placeholder={t("enter.text")}
                    onChange={(event) => this.handleChange(event)}
                  />
                  {this.state.error && this.state.name.length <= 0 && (
                    <p style={{ color: "red" }}>{t("fillArea.text")} </p>
                  )}
                </div>
                <div className="area flex flex-column left width50">
                  <label className="text-sm text-medium mb-3">
                    {t("yourNumber.text")}
                  </label>

                  <PhoneInput
                    country={"uz"}
                    value={this.state.phone}
                    onChange={(phone) => this.setState({ phone })}
                  />
                  {this.state.error && this.state.phone.length <= 0 && (
                    <p style={{ color: "red" }}>{t("fillArea.text")} </p>
                  )}
                </div>
                <div className=" area flex flex-column left ">
                  <label className="text-sm text-medium">
                    {t("dayService.text")}
                  </label>
                  <input
                    className="base-input"
                    type="date"
                    name="to_date"
                    value={this.state.to_date}
                    onChange={(event) => this.handleChange(event)}
                    min={date}
                  />

                  {this.state.error && this.state.to_date.length <= 0 && (
                    <p style={{ color: "red" }}>{t("fillArea.text")} </p>
                  )}
                </div>
              </div>
              <div className="form-item mt-1">
                <div className="area flex flex-column width50">
                  <label className="text-sm text-medium">
                    {t("region.text")}
                  </label>
                  <select
                    className="base-input disabled"
                    disabled
                    name="region"
                    onChange={(event) => this.handleChange(event)}
                  >
                    <option value={this.state.region_id}>
                      {this.state.region[this.name]}
                    </option>
                  </select>
                </div>
                <div className="area flex flex-column width50 left">
                  <label className="text-sm text-medium">
                    {t("district.text")}
                  </label>
                  <select
                    className="base-input pointer"
                    name="district"
                    onChange={(event) => this.handleChange(event)}
                    defaultValue={t("chose.text")}
                  >
                    <option disabled value={t("chose.text")}>
                      {t("chose.text")}
                    </option>
                    {this.state.districts.map((district) => (
                      <option value={district.id} key={district.id}>
                        {district[this.name]}
                      </option>
                    ))}
                  </select>
                  {this.state.error && this.state.district.length <= 0 && (
                    <p style={{ color: "red" }}>{t("fillArea.text")} </p>
                  )}
                </div>
              </div>

              <div className="form-item mt-1">
                <div className="area flex flex-column width50">
                  <label className="text-sm text-medium">
                    {t("address.text")}
                  </label>
                  <input
                    className="base-input"
                    type="text"
                    placeholder={t("enter.text")}
                    name="address"
                    onChange={(event) => this.handleChange(event)}
                  />
                  {this.state.error && this.state.address.length <= 0 && (
                    <p style={{ color: "red" }}>{t("fillArea.text")} </p>
                  )}
                </div>
              </div>
              <div className="form-item mt-1">
                <div className="area flex flex-column width100">
                  <label className="text-sm text-medium">
                    {t("addComment.text")}
                  </label>
                  <input
                    className="base-input"
                    type="text"
                    placeholder={t("enter.text")}
                    name="comment"
                    onChange={(event) => this.handleChange(event)}
                  />
                </div>
              </div>
              {/* ending input forms */}
              {/* submittin button */}
              <div className="modal-buttons pt-2">
                <PrimaryButton
                  onClick={() => {
                    !countdown && this.middlefunction();
                  }}
                  value={t("sendSMS.text")}
                  className={countdown && "disabled"}
                />
                {countdown && (
                  <Countdown
                    date={Date.now() + 180000}
                    precision={3}
                    onComplete={() => {
                      this.setState({ countdown: false });
                    }}
                  />
                )}
              </div>
            </div>
            {/* OTP verifying form */}
            {service_id && (
              <div className="basket-left-bottom d-flex flex-column">
                <div className="form-item mt-1">
                  <div className="flex flex-column width100">
                    <label className="text-sm text-medium">
                      {" "}
                      {t("OTP.text")}
                    </label>
                    <input
                      className="base-input"
                      as="input"
                      onChange={(e) => {
                        this.setState({ otp: e.target.value });
                      }}
                      name="otp"
                      placeholder={t("OTPPlaceHolder.text")}
                      type="number"
                      min={10000}
                      max={99999}
                      maxLength={5}
                      pattern="\d{5}"
                    />
                  </div>
                </div>
                <div className="modal-buttons pt-2">
                  <PrimaryButton
                    onClick={this.verifyOTP}
                    value={t("OTPVerify.text")}
                  />
                </div>
                {invalidOTP && (
                  <p style={{ color: "red" }}>{t("InvalidOTP.text")}</p>
                )}
                {MaximumOTP && (
                  <p style={{ color: "red" }}>{t("MaximumOTP.text")}</p>
                )}
                {AlreadyOTP && (
                  <p style={{ color: "red" }}>{t("AlreadyOTP.text")}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(freeSize);
