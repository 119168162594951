/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import he from "he";

import { withRouter } from "react-router-dom";

class BlogCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  removeTags = (content) => {
    content = he.unescape(content);
    const regex = /<[^<]+?>/gi;
    content = content.replace(regex, "");
    return content;
  };

  render() {
    return (
      <div
        className="blog-image"
        onClick={() => this.props.history.push(`post/${this.props.id}`)}
      >
        <img src={this.props.image} alt={this.props.nameUz} />
        <div className="blog-info">
          <div className="d-flex flex-column">
            <h1 className="text-lg">{this.props.titleUz}</h1>

            {this.props.textUz && (
              <article className="text-sm mt-4 two-line-text">
                {" "}
                {this.removeTags(this.props.textUz)}
              </article>
            )}
          </div>
          <div className="blog-date mb-2">
            <i className="fa-regular fa-calendar icon" />
            <p className="mb-0">{this.props.createdAt.slice(0, 10)}</p>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(BlogCard);
