/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import { withTranslation } from "react-i18next";
import { OutlineSecondaryButton } from "../assets/static/js/Button";
import CurrencyFormat from "react-currency-format";

class ProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div className="item-card">
        <img src={this.props.image} alt={this.props.name} />
        <div className="extra-infos">
          {this.props.isNew && (
            <span className="small-span-button new">{t("news.text")}</span>
          )}
          {this.props.isTrend && (
            <span className="small-span-button trend">{t("isTrend.text")}</span>
          )}
          {this.props.isDiscount > 0 && (
            <span className="small-span-button discount">
              {t("discount.text")}
            </span>
          )}
        </div>
        <div className="bottom-infos d-flex flex-column">
          <div className="small-infos d-flex flex-column">
            <h1 className="text-xs">{this.props.articul}</h1>
            <h1 className="text-lg"> {this.props.name} </h1>
            {this.props.models} {this.props.models ? t("models.text") : ""}
          </div>
          <div className="price-infos mt-1 d-flex flex-wrap">
            <div className="mb-3">
              {this.props.isDiscount > 0 && (
                <span className="text-sm text-through text-nowrap">
                  <CurrencyFormat
                    value={this.props.price}
                    displayType={"text"}
                    thousandSeparator=" "
                    suffix={t("suffix.text")}
                  />
                </span>
              )}

              <br />
              <span className="text-sm bold text-nowrap">
                <CurrencyFormat
                  value={this.props.price - this.props.isDiscount}
                  displayType={"text"}
                  thousandSeparator=" "
                  suffix={t("suffix.text")}
                />
              </span>
            </div>
            <div className="button-wrapper">
              <OutlineSecondaryButton value={t("more.text")} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(ProductCard);
