import axios from "axios";
import React from "react";
import { BaseUrl } from "../common/api";
import Cookies from "universal-cookie";
import ReactHtmlParser from "html-react-parser";

import { withRouter } from "react-router-dom";
import { PrimaryButton } from "../assets/static/js/Button";

const cookies = new Cookies();
class services extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables
    this.state = {
      obj: {},
    };
    this._isMounted = false;
    // detecting the language
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.title = "titleUz";
      this.text = "textUz";
    } else {
      this.title = "titleRu";
      this.text = "textRu";
    }
  }
  componentDidMount() {
    this._isMounted = true;
    // fetching data from static pages id=5 => (essential)
    axios.get(BaseUrl + "pages/5/").then((res) => {
      const obj = res.data;
      this._isMounted &&
        this.setState({
          obj,
        });
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      <div className="main">
        <div className="container">
          <div>
            <h1 className="text-title">{this.state.obj[this.title]}</h1>
          </div>
          <div className="py-2">
            {/* data generated by tiny */}
            {this.state.obj.textUz &&
              ReactHtmlParser(this.state.obj[this.text])}
          </div>
          {/* redirecting to freesize.js */}
          <PrimaryButton
            onClick={() => this.props.history.push("/freesize")}
            value="Xizmatdan foydalanish"
            style={{ width: "205px" }}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(services);
