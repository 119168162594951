import React from 'react';
import { Link } from 'react-router-dom';
import image from '../assets/static/img/xatolik.png';
import { SecondaryButton } from '../assets/static/js/Button';

export default class ErrorPage extends React.Component {

    render() {
        return (
            <div className="container d-flex flex-column align-items-center">
                <div className="d-flex align-center justify-content-center pt-4">
                    <img src={image} alt="404" />
                </div>
                    <h3 className='text-title pt-3'>Serverda xatolik yuz berdi</h3>
                    <Link to="/">

                    <SecondaryButton value="Bosh sahifaga qaytish" />
                    </Link>

            </div>
        )
    }
}