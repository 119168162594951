import { combineReducers } from "redux";
import {
  GET_ALL_PRODUCT,
  GET_NUMBER_CART,
  ADD_CART,
  DECREASE_QUANTITY,
  INCREASE_QUANTITY,
  DELETE_CART,
  CLEAR_CART,
} from "../Actions";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const cart = cookies.get("Cart") ? cookies.get("Cart") : [];

const initProduct = {
  numberCart: cart.length,
  Carts: cart,
  _products: [],
};

function todoProduct(state = initProduct, action) {
  switch (action.type) {
    case GET_ALL_PRODUCT:
      return {
        ...state,
        _products: action.payload,
      };
    case GET_NUMBER_CART:
      return {
        ...state,
      };
    case ADD_CART:
      // if (state.numberCart == 0) {
      //   let cart = {
      //     id: state.Carts.id,
      //     quantity: 1,
      //     name: action.payload.name,
      //     image: action.payload.image,
      //     price: action.payload.price,
      //   };
      //   state.Carts.push(cart);
      //   state.numberCart++;
      // } else {

      // let check = false;
      // console.log("carts:", state._todoProduct);
      // state.Carts.map((item, key) => {
      //   if (item.id === state.Carts[key].id) {
      //     console.log(action.payload.id);
      //     check = true;
      //   } else {
      //     state.numberCart++;
      //   }
      // });
      // if (!check) {
      //   let _cart = {
      //     id: action.payload.id,
      //     quantity: 1,
      //     name: action.payload.name,
      //     image: action.payload.image,
      //     price: action.payload.price,
      //   };
      //   state.Carts.push(_cart);
      //   state.numberCart++;
      // }
      // }
      return {
        ...state,
        numberCart: state.numberCart + 1,
      };
    case INCREASE_QUANTITY:
      state.numberCart++;
      state.Carts[action.payload].quantity++;

      return {
        ...state,
      };
    case DECREASE_QUANTITY:
      let quantity = state.Carts[action.payload].quantity;
      if (quantity > 1) {
        state.numberCart--;
        state.Carts[action.payload].quantity--;
      }

      return {
        ...state,
      };
    case DELETE_CART:
      // let quantity_ = state.Carts[action.payload].quantity;
      state.numberCart--;
      return {
        ...state,

        // Carts: state.Carts.filter((item) => {
        //   return item.id != state.Carts[action.payload].id;
        // }),
      };

    case CLEAR_CART:
      state.numberCart = 0;
      return {
        ...state,
      };
    default:
      return state;
  }
}
const ShopApp = combineReducers({
  _todoProduct: todoProduct,
});
export default ShopApp;
