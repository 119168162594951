import React from "react";

import axios from "axios";
import { Link } from "react-router-dom";

import { BaseUrl } from "../common/api";
import { withTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { Suspense } from "react";
import ProductCardSkeleton from "../common/productSkeleton";

import { SecondaryButton } from "../assets/static/js/Button";

const cookies = new Cookies();
const ProductCard = React.lazy(() => import("../common/product-card"));
const Sidebar = React.lazy(() => import("./sidebar"));
const Paginate = React.lazy(() => import("./paginate"));
class Category extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables
    this.state = {
      nameUz: "",
      nameRu: "",
      category: [],
      allDate: [],
      nPages: 1,
      currentPage: 1,
      recordsPerPage: 1,
      notFound: false,
      filterShow: false,
      openfilter: true,
      limit: 21,
      offset: 0,
      total: 0,
      redirect: "",
      isLoading: true,
    };
    this._isMounted = false;
    this.collection = [];

    // detecting the language

    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
    } else {
      this.name = "nameRu";
    }
    this.collection = [];
    this.updated = 0;
  }
  async componentDidMount() {
    this._isMounted = true;
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    // opening or closing sidebar
    this.filterToggle();
    //  fetching data
    this.fetchData();
  }
  async componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this._isMounted = true;
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
      // opening or closing sidebar
      this.filterToggle();
      //  fetching data
      this.setState(
        {
          isLoading: true,
          category: [],
        },
        this.fetchData
      );
    }
  }

  // fetching data from api
  fetchData = async () => {
    // getting current url
    this.getQuery();
    const response = await axios.get(
      BaseUrl + `collections/${this.category_id}/categories?` + this.url
    );
    let category = response.data.results;
    if (response.count === 0 || category.length === 0) {
      this._isMounted &&
        this.setState({
          notFound: true,
        });
    } else {
      const nameUz = category[0].category_nameUz;
      const nameRu = category[0].category_nameRu;
      this._isMounted &&
        this.setState({
          notFound: false,
          nameUz,
          nameRu,
        });
    }
    this._isMounted &&
      this.setState({
        category,
        total: response.data.count,
        isLoading: false,
      });
  };
  // detec changes during pagination

  handlePageChange = (offset) => {
    this._isMounted && this.setState({ offset }, this.fetchData);
    this.parsed.offset = offset;
    this.offset = offset;
    this.url = this.queryString.stringify(this.parsed, {
      skipNull: true,
      arrayFormat: "comma",
    });
    this.props.history.push(`category?` + this.url);
  };
  // getting current url and modify it
  getQuery = async () => {
    this.queryString = require("query-string");
    this.parsed = this.queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    this.category_id = this.parsed.category_id;
    if (this.parsed.offset) {
      this.offset = this.parsed.offset;
    } else {
      this.offset = this.state.offset;
    }
    if (this.parsed.limit) {
      this.url = this.queryString.stringify(this.parsed, {
        arrayFormat: "comma",
      });
    } else {
      this.url =
        this.queryString.stringify(this.parsed, { arrayFormat: "comma" }) +
        `&limit=${this.state.limit}`;
    }
    this.redirect = this.queryString.parse(this.url, { arrayFormat: "comma" });
    this.redirect["offset"] = 0;
    this.redirect = this.queryString.stringify(this.redirect, {
      arrayFormat: "comma",
    });
    this._isMounted &&
      this.setState({
        redirect: this.redirect,
      });
  };
  // sending info to child component "Sidebar" it will work whenever width of window is lower than 576px
  filterToggle = () => {
    if (window.innerWidth < 576) {
      this._isMounted &&
        this.setState({ filterShow: false, openfilter: false });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.filterToggle);
    this._isMounted = false;
  }

  render() {
    const { t } = this.props;
    const { limit, total } = this.state;
    return (
      <div className="main">
        {/* Suspens needs for lazyloading  */}
        <Suspense fallback={<div>Loading...</div>}>
          <div className="container">
            <div className="link-div">
              {/* home page redirect button */}
              <p
                onClick={() => this.props.history.push("/")}
                className="text-sm pointer"
              >
                {t("mainPage.text")}
              </p>
              <span>&nbsp; / &nbsp;</span>
              <span> {this.state[this.name]}</span>
            </div>
            <div className="info pt-2">
              {/* button for opeing or closing filters */}
              <SecondaryButton
                onClick={() =>
                  this.setState({
                    filterShow: !this.state.filterShow,
                    openfilter: !this.state.openfilter,
                  })
                }
                value={
                  this.state.openfilter
                    ? t("FiltersClose.text")
                    : t("FiltersOpen.text")
                }
              />
              <h1 className="text-lg title">{t("Collections.text")}</h1>
            </div>

            <div className="collections-container">
              {/* child component which shows filters */}
              <Sidebar
                filterShow={this.state.filterShow}
                filterToggle={this.filterToggle}
              />
              {/* if filters didn't match any element notFound text will show */}
              {this.state.notFound ? (
                <h3>{t("notFound.text")}</h3>
              ) : (
                <div
                  className={` pt-2 ${
                    this.state.filterShow ? "collections" : "collections-small"
                  } `}
                >
                  {/* Skeleton for product card */}
                  {this.state.isLoading && (
                    <ProductCardSkeleton cards={this.state.limit} />
                  )}
                  {/* iterating category elements by ProductCard component */}
                  {this.state.category.map((cat) => (
                    <Link
                      key={cat.id}
                      to={
                        `/collection?collection=${cat.id}&` +
                        this.state.redirect
                      }
                    >
                      <ProductCard
                        key={cat.id}
                        image={cat.image}
                        name={cat.nameUz}
                        price={cat.price}
                        // models={cat.products.length}
                        isTrend={cat.isTrend}
                        isNew={cat.isNew}
                        isDiscount={cat.discount}
                      />
                    </Link>
                  ))}
                </div>
              )}
            </div>
            {/* Pagination  */}
            <Paginate
              limit={limit}
              total={total}
              offset={this.offset}
              handlePageChange={this.handlePageChange}
            />
          </div>
        </Suspense>
      </div>
    );
  }
}
export default withTranslation()(Category);
