// Copy code
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

class MyComponent extends React.Component {
  state = {
    loading: true,
    data: null,
  };

  componentDidMount() {
    // Simulate loading data from an API
    setTimeout(() => {
      this.setState({
        loading: false,
        data: {
          title: "My Blog Post",
          content: "Lorem ipsum dolor sit amet...",
        },
      });
    }, 2000);
  }

  render() {
    const { loading, data } = this.state;

    return (
      <div>
        <h1>{loading ? <Skeleton height={50} width={300} /> : data.title}</h1>
        <p>{loading ? <Skeleton width={250} /> : data.content}</p>
      </div>
    );
  }
}
export default MyComponent;
