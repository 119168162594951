/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/static/logo.svg";
import { useHistory } from "react-router-dom";

import Cookies from "universal-cookie";
import axios from "axios";
import { connect } from "react-redux";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import {Popover, Overlay} from "react-bootstrap"
import ProgressBar from "./ProgressBar";



import { BaseUrl } from "./api";

const current = new Date();
var nextYear = new Date();
nextYear.setFullYear(current.getFullYear() + 1);

function withParams(Component) {
  return (props) => <Component {...props} history={useHistory()} />;
}
const cookies = new Cookies();
const lngs = [
  { code: "uz", nativeName: "O`z" },
  { code: "ru", nativeName: "Ру" },
];
class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: [],
      search: "",
      show: false,
      regions: [],
      region: "",
      lngShow: false,
      query: "",
      elements: false,
      target: null,
      target_show: false,
      progress: 0,
    };
    this._isMounted = false;
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
      this.language = "O`z";
    } else {
      this.name = "nameRu";
      this.language = "Ру";
    }
    this.showModal = this.showModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.buttonRef = React.createRef();
    this.length = 0;
  }
  async componentDidMount() {
    this._isMounted = true;
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    let data = [];

    let region = "Hudud";
    await axios.get(BaseUrl + "regions/").then((res) => {
      this.regions = res.data;
      this._isMounted &&
        this.setState({
          regions: this.regions,
        });
    });
    if (cookies.get("Region")) {
      let id = parseInt(cookies.get("Region"));
      let reg = this.regions.find((item) => item.id === id);
      region = reg[this.name];
    } else {
      region = this.regions[0][this.name];

      this.interval = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.progress >= 100) {
            this.setYesButton();
            clearInterval(this.interval);
            return { progress: 100 };
          }
          cookies.set("Region", this.regions[0].id);
          return { progress: prevState.progress + 1 };
        });
      }, 100);
     

      this._isMounted &&
        this.setState({
          target: this.buttonRef.current, 
          target_show: true
        });
    }
    if (cookies.get("Cart")) {
      data = cookies.get("Cart");
    }
    this._isMounted &&
      this.setState({
        cart: data.length,
        region,
      });
  }

  setYesButton = () => {
    this.setState({ target_show: false });
    cookies.set("Region", this.regions[0].id);
  }
  componentDidUpdate(prevProps) {
    if (this.props.parentData !== prevProps.parentData) {
      this._isMounted = true;
      this._isMounted &&
        this.setState(
          {
            cart: this.props.parentData,
          },
          () => console.log(this.state.cart)
        );
    }
  }
  showModal = () => {
    this._isMounted &&
      this.setState(
        {
          show: true,
        },
        () => console.log(this.state.show)
      );
  };
  hideModal = () => {
    this._isMounted &&
      this.setState({
        show: false,
      });
  };
  handleClick = (e) => {
    e.preventDefault();
    console.log(e.target.name);
    cookies.set("Region", e.target.name, { expires: nextYear });
    let id = parseInt(cookies.get("Region"));
    const reg = this.state.regions.find((item) => item.id === id);
    console.log(this.state.regions);
    this._isMounted &&
      this.setState(
        {
          region: reg.name,
        },
        () => console.log(this.state.region)
      );
    this.hideModal();
    window.location.reload(false);
  };
  handleLanguage = (lng) => {
    this._isMounted &&
      this.setState({
        lngShow: false,
      });
    i18n.changeLanguage(lng);
    cookies.set("Language", lng);
    window.location.reload(false);
  };
  getQuery = (e) => {
    if (e.key === "Enter") {
      this.props.history.push(`/searcht?offset=0&query=` + e.target.value);
    }
  };
  closeContent = () => {
    this._isMounted &&
      this.setState({
        elements: false,
      });
  };
  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval);
  }
  render() {
    const { t } = this.props;
    const history = this.props.history;
    return (
      <div className="header">
        <header>
          <div>
            <section>
              <div
                id="myModal"
                className={this.state.show ? `mod modalOpen` : "mod"}
              >
                <div className="modalContent flex center">
                <button className="close-button item-button gray " onClick={() => this.hideModal()}>
                  <i className="fa-solid fa-xmark"></i>
                </button>
                  <h1 className="text-title">{t("choseLocation.text")}</h1>

                  <p className="pt-1 text">{t("availableShops.text")}:</p>
                  <div className="regions-container mt-2">
                    {this.state.regions
                      .filter((el) => el.isActive === true)
                      .map((item) => (
                        <button
                          key={item.id}
                          onClick={(e) => this.handleClick(e)}
                          name={item.id}
                          className="region"
                        >
                          {item[this.name]}
                        </button>
                      ))}
                  </div>

                  <hr className="mt-2" />
                  <p className="pt-1 text">{t("sagSoon.text")}:</p>
                  <div className="regions-container mt-2">
                    {this.state.regions
                      .filter((el) => el.isActive === false)
                      .map((item) => (
                        <button
                          key={item.id}
                          name={item.id}
                          className="region disabled"
                        >
                          {item[this.name]}
                        </button>
                      ))}
                  </div>
                </div>
              </div>

              {/* <form className="col-5">  */}
              <div className="left-header">
                <div className="pointer">
                  <Link to="/">
                    <img 
                    ref={this.buttonRef}
                    src={logo} alt="logo" />{" "}
                  </Link>
                  <Overlay target={this.state.target} show={this.state.target_show} placement="bottom">
                    <Popover id="popover-basic" style={{ position: "relative", top: "0px" }}>
                      <Popover.Title as="h3">{t("yourcity.text")}{this.state.region}?</Popover.Title>
                      <ProgressBar  />
                      <Popover.Content className="modal-buttons">
                      <button className=" btn-primary" onClick={this.setYesButton} type="button">Да</button>
                      <button className=" btn-secondary" onClick={() => this.setState({target_show: false, show: true })} type="button">Нет</button>

{/*                        
                          <div className="col-6 d-flex justify-content-start">
                            <button className=" btn-primary" onClick={() => this.setState({ target_show: false })} type="button">Yes</button>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                            <button className=" btn-secondary" onClick={() => this.setState({target_show: false, show: true })} type="button">No</button>
                          </div> */}
                      </Popover.Content>
                    </Popover>
                  </Overlay>
                </div>
                <div className="search-input">
                  <div className="search-icon"></div>
                  <div>
                    <input
                      onKeyDown={(e) => this.getQuery(e)}
                      onChange={(e) =>
                        this._isMounted &&
                        this.setState({ query: e.target.value })
                      }
                      className={this.state.query.length > 0 ? "focus" : ""}
                      type="search"
                      name="query"
                      value={this.state.query}
                      placeholder={t("searchPlaceholder.text")}
                    />
                  </div>
                </div>
              </div>
              <div className="right-header">
                <Link to="/services" className="text services">
                  {t("services.text")}
                </Link>
                <span className="vertical-line"></span>
                <div className="pointer location">
                  <i className="fa-regular fa-map fa-sm icon"></i>
                  <span className="text" onClick={() => this.showModal()}>
                    {" " + this.state.region}
                  </span>
                </div>
                <div className="dropdown">
                  <button
                    className="dropbtn"
                    onClick={() =>
                      this._isMounted && this.setState({ lngShow: true })
                    }
                  >
                    {this.language}
                  </button>
                  <div
                    className={
                      this.state.lngShow
                        ? "dropdown-content show"
                        : "dropdown-content"
                    }
                  >
                    {lngs.map((lng) => {
                      return (
                        <p
                          className=" p-2  language"
                          key={lng.code}
                          type="submit"
                          onClick={() => this.handleLanguage(lng.code)}
                        >
                          {lng.nativeName}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="cart pointer"
                  onClick={() => history.push("/cart")}
                >
                  <i className="fa-solid fa-cart-shopping icon"></i>
                  {this.props.numberCart !== 0 && (
                    <span className="basket-badge text-xs">
                      {this.props.numberCart}
                    </span>
                  )}
                </div>
                <div
                  className="bar-menu pointer"
                  onClick={() =>
                    this._isMounted &&
                    this.setState({
                      elements: true,
                    })
                  }
                >
                  <i className="fa-solid fa-bars fa-2xl"></i>
                </div>
              </div>
            </section>
          </div>
          {this.state.elements === true && (
            <div className="elements">
              <div className="open-content text-lg">
                <div className="pointer " onClick={() => this.closeContent()}>
                  <Link to="/services">{t("services.text")}</Link>
                </div>
                <div
                  className="pointer"
                  style={{ paddingBottom: "10px" }}
                  onClick={() => this.closeContent()}
                >
                  <i className="fa-regular fa-map fa-sm icon"></i>
                  <span onClick={() => this.showModal()}>
                    {" " + this.state.region}
                  </span>
                </div>
                <div>
                  <button
                    className="dropbtn text-lg"
                    onClick={() => {
                      this._isMounted && this.setState({ lngShow: true });
                    }}
                  >
                    <i className="fa-solid fa-earth-europe px-1 icon"></i>
                    {this.language}
                  </button>
                  <div
                    className={
                      this.state.lngShow
                        ? "dropdown-content show"
                        : "dropdown-content"
                    }
                  >
                    {lngs.map((lng) => {
                      return (
                        <p
                          className=" p-2  language"
                          key={lng.code}
                          type="submit"
                          onClick={() => {
                            this.handleLanguage(lng.code);
                            this.closeContent();
                          }}
                        >
                          {lng.nativeName}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="pointer"
                  onClick={() => {
                    history.push("/cart");
                    this.closeContent();
                  }}
                  style={{ display: "flex" }}
                >
                  <span>
                    <i
                      className="fa-solid fa-cart-shopping icon"
                      style={{ marginRight: "0" }}
                    ></i>
                  </span>
                  {this.props.numberCart ? (
                    <span
                      className="basket-badge text-xs"
                      style={{ top: "5px" }}
                    >
                      {this.props.numberCart}
                    </span>
                  ) : (
                    <span style={{ marginRight: "15px" }}></span>
                  )}
                  <span>{t("cart.text")}</span>
                </div>
                <div style={{ paddingBottom: "10px" }}>
                  <img src={logo} alt="logo" />{" "}
                </div>
                <div
                  className="close-icon"
                  style={{ fontSize: "30px" }}
                  onClick={() => this.closeContent()}
                >
                  <i className="fa-solid fa-xmark icon"></i>
                </div>
              </div>
            </div>
          )}
        </header>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    numberCart: state._todoProduct.numberCart,
  };
};
export default withParams(
  connect(mapStateToProps, null)(withTranslation()(AppHeader))
);
